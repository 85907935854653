<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.selectType')"
    :visible.sync="dialogVisible"
    width="50%"
    top="10px"
    append-to-body>
    <div class="custom-tree-container">
      <div v-loading="loading" style="margin: 20px 40px 0px 0px;">
        <el-input
          v-model="filterText"
          style="width: 200px;"
          :placeholder="$t('common.keyWord')"></el-input>
        <el-tree
          ref="tree"
          :data="data"
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false">
          <span slot-scope="{ node, data }" class="custom-tree-node">
            <div style="width: 100%;" @click="onSelect(data)">{{node.label}}</div>
          </span>
        </el-tree>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: "PartsTypeSelect",
    data() {
      return {
        filterText: "",
        dialogVisible: false,
        data: [],
        loading: false,
        defaultProps: {
          children: "children",
          label: "name",
        },
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.filterText = "";
        this.getData();
      },
      getData() {
        this.loading = true;
        this.$axios
          .get("partsTypes/tree").then((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res.data));
              this.loading = false;
            } else {
              this.$message.error("获取数据出错");
              this.loading = false;
            }
          });
      },
      filterNode(value, data, node) {
        //console.log(data);
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return node.label.indexOf(value) !== -1;
      },
      onSelect(data) {
        this.$emit("select", {"id": data.id, "name": data.name});
        this.dialogVisible = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
