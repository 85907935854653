import { render, staticRenderFns } from "./PartsTypeSelect.vue?vue&type=template&id=3f884201&scoped=true"
import script from "./PartsTypeSelect.vue?vue&type=script&lang=js"
export * from "./PartsTypeSelect.vue?vue&type=script&lang=js"
import style0 from "./PartsTypeSelect.vue?vue&type=style&index=0&id=3f884201&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f884201",
  null
  
)

export default component.exports